import React, { useState } from "react";
import RBIback from "../../assets/svg/RBIHoverCard.svg";
import isbimage from "../../assets/svg/ISBHoverCard.svg";
import Krushai from "../../assets/svg/krushAI.svg";
import tataneu from "../../assets/svg/tataNeuHoverCard.svg";
import RBIbackHover from "../../assets/svg/RBIHoveredCard.svg";
import isbimageHover from "../../assets/svg/ISBHoveredCard.svg";
import KrushaiHover from "../../assets/svg/KrushAIHoveredCard.svg";
import tataneuHover from "../../assets/svg/TataNeuHoveredCard.svg";

const ExpertiseSection = () => {
  const cardDataArray = [
    {
      image: RBIback,
      text: (
        <>
          <div className="mb-2">
            <span className="font-bold 2xl:text-3xl mb-5">
              RBI - Innovation Hub's AI-Driven Grievance Resolution:
            </span>
          </div>
          <span className="font-normal text-base sm:text-xs xl:text-lg lg:text-base md:text-xs 2xl:text-2xl ">
            Our RBI Grievance Resolution application is an AI driven solution
            that efficiently handles customer complaints using NLP and RAG
            technology. We accurately categorize issues, deliver real-time
            responses, and seamlessly escalate complex cases to the relevant RBI
            departments, ensuring swift resolutions and enhanced service
            quality. In this project we did on-prem deployments for multiple
            banks.
          </span>
        </>
      ),
      mobileText: "More About RBI",
      hoveredImg: RBIbackHover,
    },
    {
      image: isbimage,
      text: (
        <>
          <div className="mb-2">
            <span className="font-bold text-base 2xl:text-3xl">
              ISB Gen-AI Interviewer:
            </span>
          </div>
          <span className="font-normal  text-base sm:text-xs lg:text-base md:text-xs xl:text-lg 2xl:text-2xl">
            Our Gen-AI based interview platform that conducts interviews and
            shortlists candidates based on their profiles. We optimised LLM
            models to craft highly personalized interviews, score and evaluate
            responses based on predefined criterias and personality traits. We
            had an accuracy of 96% as verfied by ISB professors.
          </span>
        </>
      ),
      mobileText: "More About ISB",
      hoveredImg: isbimageHover,
    },
    {
      image: Krushai,
      text: (
        <>
          <div className="mb-2">
            <span className="font-bold  2xl:text-3xl ">
              KrushAI - Empowering Farmers:
            </span>
          </div>
          <span className=" font-normal  text-[13px] sm:text-xs lg:text-base  md:text-xs xl:text-lg  text-xs-370  2xl:text-2xl">
            Our agricultural assistant, KrushAI, provides farmers with real-time
            information and guidance in their native language on government
            schemes, crop cycles, and best practices. This multilingual
            application is designed to empower farmers with the knowledge they
            need to optimize their yields and improve their livelihoods.
          </span>
        </>
      ),
      mobileText: "More About KrushAI",
      hoveredImg: KrushaiHover,
    },
    {
      image: tataneu,
      text: (
        <>
          <div className="mb-2">
            <span className="font-bold 2xl:text-3xl ">
              A Multilingual Conversational Assistant:
            </span>
          </div>
          <span className="font-normal text-base sm:text-xs lg:text-base  xl:text-lg md:text-xs 2xl:text-2xl">
            Our LLM-based multilingual assistant is meticulously trained on
            custom datasets, allowing us to provide users with accurate and
            insightful answers to their queries. This intelligent assistant
            engages in natural, conversational exchanges, ensuring a seamless
            experience that simplifies complex information and enhances user
            understanding.
          </span>
        </>
      ),
      mobileText: "More About Samagra",
      hoveredImg: tataneuHover,
    },
  ];

  const HoverCard = ({ image, text, mobileText, hoveredImg }) => {
    const [isMobileTextVisible, setIsMobileTextVisible] = useState(false);

    const handleMobileTextClick = () => {
      console.log("hellow");
      setIsMobileTextVisible(true);
      setTimeout(() => {
        setIsMobileTextVisible(false);
      }, 3000);
    };

    return (
      <div
        className="relative overflow-hidden rounded-lg cursor-pointer group"
        onClick={handleMobileTextClick}
      >
        {isMobileTextVisible ? (
          <img
            src={hoveredImg}
            alt="Card Image"
            className="object-contain transition-transform duration-300 ease-in-out w-full h-full "
          />
        ) : (
          <img
            src={image}
            alt="Card Image"
            className="object-contain transition-transform duration-300 ease-in-out w-full h-full "
            onClick={handleMobileTextClick}
          />
        )}
        {/* Desktop text - shown on hover for larger screens */}
        <div
          className={`absolute  inset-0 flex items-top justify-center items-center bg-white text-black
            opacity-0 md:group-hover:opacity-100 transition-all duration-300 ease-in-out ${
              isMobileTextVisible ? "md:opacity-0" : ""
            }`}
        >
          <img
            src={hoveredImg}
            alt="Card Image"
            className="object-contain transition-transform duration-300 ease-in-out w-full h-full "
          />
        </div>
      </div>
    );
  };

  return (
    <div className="w-full mx-auto md:pt-[50px]">
      {/* H2 Section */}
      <h2
        className=" pt-[30px] pb-[30px] pl-[40px]  
      pr-[40px] md:pl-[150px] md:pr-[150px] text-Outfit text-[20px]
       md:text-[38px] font-semibold lg:leading-[60px] leading-[25.2px]
        md:leading-[48px] text-[#0F0F0F] lg:text-5xl text-center"
      >
        We Trained LLM Models & Building generative AI powered solutions for
      </h2>

      {/* Card Grid Section */}
      <div
        className="grid grid-cols-1 md:grid-cols-2 gap-y-[45px] gap-x-[80px]
      px-[10px] md:px-[80px] mt-[20px] md:mt-[0px]"
      >
        {cardDataArray.map((card, index) => (
          <HoverCard
            key={index}
            image={card.image}
            hoveredImg={card.hoveredImg}
            text={card.text}
            mobileText={card.mobileText}
          />
        ))}
      </div>
      <style jsx>{`
        @media (max-width: 370px) {
          .text-xs-370 {
            font-size: 12px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default ExpertiseSection;
