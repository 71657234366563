import React, { useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import mapImg from "../../assets/img/mapImg.png";

const Globe1 = () => {
  // State to determine if the device is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="relative mt-[10px] lg:mt-0 w-full  overflow-hidden">
      <div className="w-full  flex justify-center items-center">
        {isMobile ? (
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={0}
            defaultPositionY={0}
            options={{ limitToBounds: true }}
            doubleClick={{ disabled: true }} // Disable double-click zoom
            pinch={{ step: 5 }} // Control pinch sensitivity
          >
            <TransformComponent>
              <img src={mapImg} alt="img" className="w-full  " />
            </TransformComponent>
          </TransformWrapper>
        ) : (
          <img src={mapImg} alt="img" className="w-full " />
        )}
      </div>
    </div>
  );
};

export default Globe1;
