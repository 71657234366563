// import React, { useState, useEffect } from "react";
// import { motion } from "framer-motion";
// import heroBgSvg from "../../assets/HeroBgSVG.svg";
// import heroBg1Svg from "../../assets/HeroBg1Svg.svg";

// export default function Hero() {
//   const windowWidth = document.documentElement.clientWidth;
//   const [showHeroSection2, setShowHeroSection2] = useState(false);

//   // Use useEffect to toggle the hero section after 2 seconds
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowHeroSection2(true);
//     }, 5000);

//     // Cleanup the timer when the component unmounts
//     return () => clearTimeout(timer);
//   }, []);

//   // Common animation settings for both sections
//   const heroAnimation = {
//     initial: { opacity: 0, y: "100vh" },
//     animate: { opacity: 1, y: 0 },
//     transition: { delay: 0.5, duration: 1 },
//   };

//   return (
//     <div>
//       {/* Conditionally render the hero sections based on the state */}
//       {!showHeroSection2 ? (
//         <motion.div
//           className="sm:h-screen h-screen md:pt-[35vh] pt-[40vh] lg:pt-[40vh] bg-black pb-20 lg:pb-20 relative overflow-hidden
//           color-change-animation lg:pt-[100px] 2xl:pt-[40vh] 3xl:pt-[40vh]"
//           style={{ paddingTop: windowWidth >= 1800 ? "40vh" : "" }}
//           {...heroAnimation}
//         >
//           <img src={heroBgSvg} alt="" className="bgitem" />
//           <motion.img
//             src={exampleGif}
//             alt="Example GIF"
//             className="mx-auto mt-8" // You can adjust the styling as needed
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             transition={{ delay: 1, duration: 1 }}
//           />
//           <motion.div
//             initial={{ y: "100vh" }}
//             animate={{ y: 0 }}
//             transition={{
//               delay: 0.5,
//               duration: window.innerWidth <= 780 ? 1.2 : 1,
//             }}
//           >
//             <h1 className="font-bold text-4xl lg:text-8xl 2xl:mt-[0] lg:pt-35 text-center">
//               Don’t let <span style={{ color: 'rgb(100,151,254)' }}>tech</span>
//             </h1>
//             <h1 className="font-light text-4xl lg:text-8xl text-center mb-5 lg:mb-12">
//               <span>become a barrier!</span>
//             </h1>
//           </motion.div>
//           <motion.div
//             initial={{ y: "100vh" }}
//             animate={{ y: 0 }}
//             transition={{ delay: 2.3, duration: 1 }}
//           >
//             <p className="w-full mx-auto px-4 md:pr-[40px] md:pl-[40px] text-center font-normal text-base md:text-3xl md:block lg:text-3xl lg:block">
//               We leverage AI as an enabler, building advanced tech that brings your ideas to life.
//             </p>
//           </motion.div>
//         </motion.div>
//       ) : (
//         <motion.div
//           className="sm:h-screen h-screen md:pt-[35vh] pt-[40vh] lg:pt-[40vh] bg-black pb-20 lg:pb-20 relative overflow-hidden
//           color-change-animation lg:pt-[100px] 2xl:pt-[40vh] 3xl:pt-[40vh]"
//           style={{ paddingTop: windowWidth >= 1800 ? "40vh" : "" }}
//           {...heroAnimation}
//         >
//           {/* Rotating and Zooming Background Image */}
//           <motion.div
//             className="absolute top-0 left-0 w-full h-full"
//             animate={{ rotate: [0, 10, -10, 0], scale: [1, 3] }} // Start with a larger scale to prevent black gaps
//             transition={{
//               repeat: Infinity,
//               duration: 25, // Adjust the rotation speed
//               ease: "linear",
//               scale: {
//                 repeatType: "mirror", // Makes the zoom in and out effect
//                 duration: 20, // Adjust the zoom speed
//                 repeat: Infinity,
//               },
//             }}
//           >
//             <img
//               src={heroBg1Svg}
//               alt="Rotating Background"
//               className="w-full h-full object-cover" // Ensures the image fits the screen
//               style={{ transformOrigin: 'center' }} // Keeps rotation centered
//             />
//           </motion.div>

//           {/* Content */}
//           <div className="pl-[24px] pr-[24px] relative z-10">
//             <motion.div
//               initial={{ y: "100vh" }}
//               animate={{ y: 0 }}
//               transition={{
//                 delay: 0.5,
//                 duration: window.innerWidth <= 780 ? 1.2 : 1,
//               }}
//             >
//               <h1 className="text-white font-outfit text-[50px] md:text-8xl font-semibold md:leading-[102px] leading-[52px] text-center">
//                 Ideas are the new currency,
//               </h1>
//               <h1 className="text-white font-outfit text-[40px] md:text-8xl font-light md:leading-[102px] leading-[52px] text-center">
//                 <span>We're the bank</span>
//               </h1>
//             </motion.div>
//             <motion.div
//               initial={{ y: "100vh" }}
//               animate={{ y: 0 }}
//               transition={{ delay: 2.3, duration: 1 }}
//             >
//               <p className="font-outfit md:text-[24px] text-[20px] font-normal leading-[26px] md:leading-[44px] text-center text-white pt-[36px]">
//                 Your vision, our expertise. Let's build something extraordinary together.
//               </p>
//             </motion.div>
//           </div>
//         </motion.div>
//       )}
//     </div>
//   );
// }

// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";
// import heroBgSvg from "../../assets/HeroBgSVG.svg";
// import heroBg1Svg from "../../assets/HeroBg1Svg.svg";
// import comp1Gif from "../../assets/img/Comp1.gif";

// export default function Hero() {
//   const windowWidth = typeof window !== 'undefined' ? document.documentElement.clientWidth : 0;
//   const [showHeroSection2, setShowHeroSection2] = useState(false);
//   const [showGif, setShowGif] = useState(false);

//   useEffect(() => {
//     const gifTimer = setTimeout(() => {
//       setShowGif(true);
//     }, 3000);

//     const sectionTimer = setTimeout(() => {
//       setShowHeroSection2(true);
//     }, 5100); // 2 seconds after GIF appears

//     return () => {
//       clearTimeout(gifTimer);
//       clearTimeout(sectionTimer);
//     };
//   }, []);

//   const heroAnimation = {
//     initial: { opacity: 1, y: "100vh" },
//     animate: { opacity: 1, y: 0 },
//     transition: { delay: 0.5, duration: 1 },
//   };

//   return (
//     <div>
//       <AnimatePresence>
//         {!showHeroSection2 && (
//           <motion.div
//             key="hero1"
//             className="sm:h-screen h-screen md:pt-[35vh] pt-[40vh] lg:pt-[40vh] bg-black pb-20 lg:pb-20 relative overflow-hidden
//             color-change-animation lg:pt-[100px] 2xl:pt-[40vh] 3xl:pt-[40vh]"
//             style={{ paddingTop: windowWidth >= 1800 ? "40vh" : "" }}
//             {...heroAnimation}
//             exit={{ opacity: 0, transition: { duration: 0.5 } }}
//           >
//             <img src={heroBgSvg} alt="" className="bgitem" />
//             <motion.div
//               initial={{ y: "100vh" }}
//               animate={{ y: 0 }}
//               transition={{
//                 delay: 0.5,
//                 duration: window.innerWidth <= 780 ? 1.2 : 1,
//               }}
//             >
//               <h1 className="font-bold text-4xl lg:text-8xl 2xl:mt-[0] lg:pt-35 text-center">
//                 Don't let <span style={{ color: 'rgb(100,151,254)' }}>tech</span>
//               </h1>
//               <h1 className="font-light text-4xl lg:text-8xl text-center mb-5 lg:mb-12">
//                 <span>become a barrier!</span>
//               </h1>
//             </motion.div>
//             <motion.div
//               initial={{ y: "100vh" }}
//               animate={{ y: 0 }}
//               transition={{ delay: 2.3, duration: 1 }}
//             >
//               <p className="w-full mx-auto px-4 md:pr-[40px] md:pl-[40px] text-center font-normal text-base md:text-3xl md:block lg:text-3xl lg:block">
//                 We leverage AI as an enabler, building advanced tech that brings your ideas to life.
//               </p>
//             </motion.div>
//             {showGif && (
//               <motion.div
//                 className="absolute top-0 left-0 w-full h-full"
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 exit={{ opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } }}
//                 //transition={{ duration: 0.5, ease: "easeInOut" }}
//                 style={{ zIndex: 50 }}
//               >
//                 <img
//                   src={comp1Gif}
//                   alt="Example GIF"
//                   className="w-full h-full object-cover"
//                 />
//               </motion.div>
//             )}
//           </motion.div>
//         )}

//         {showHeroSection2 && (
//           <motion.div
//             key="hero2"
//             className="sm:h-screen h-screen md:pt-[35vh] pt-[40vh] lg:pt-[40vh] bg-black pb-20 lg:pb-20 relative overflow-hidden
//             color-change-animation lg:pt-[100px] 2xl:pt-[40vh] 3xl:pt-[40vh]"
//             style={{ paddingTop: windowWidth >= 1800 ? "40vh" : "" }}
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             transition={{ duration: 0.5 }}
//           >
//             {/* Rotating and Zooming Background Image */}
//             <motion.div
//               className="absolute top-0 left-0 w-full h-full"
//               animate={{ rotate: [0, 10, -10, 0], scale: [1, 3] }}
//               transition={{
//                 repeat: Infinity,
//                 duration: 25,
//                 ease: "linear",
//                 scale: {
//                   repeatType: "mirror",
//                   duration: 20,
//                   repeat: Infinity,
//                 },
//               }}
//             >
//               <img
//                 src={heroBg1Svg}
//                 alt="Rotating Background"
//                 className="w-full h-full object-cover"
//                 style={{ transformOrigin: 'center' }}
//               />
//             </motion.div>

//             {/* Content */}
//             <div className="pl-[24px] pr-[24px] relative z-10">
//               <motion.div
//                 initial={{ y: "100vh" }}
//                 animate={{ y: 0 }}
//                 transition={{
//                   delay: 0.5,
//                   duration: window.innerWidth <= 780 ? 1.2 : 1,
//                 }}
//               >
//                 <h1 className="text-white font-outfit text-[50px] md:text-8xl font-semibold md:leading-[102px] leading-[52px] text-center">
//                   Ideas are the new currency,
//                 </h1>
//                 <h1 className="text-white font-outfit text-[40px] md:text-8xl font-light md:leading-[102px] leading-[52px] text-center">
//                   <span>We're the bank</span>
//                 </h1>
//               </motion.div>
//               <motion.div
//                 initial={{ y: "100vh" }}
//                 animate={{ y: 0 }}
//                 transition={{ delay: 2.3, duration: 1 }}
//               >
//                 <p className="font-outfit md:text-[24px] text-[20px] font-normal leading-[26px] md:leading-[44px] text-center text-white pt-[36px]">
//                   Your vision, our expertise. Let's build something extraordinary together.
//                 </p>
//               </motion.div>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import heroBgSvg from "../../assets/HeroBgSVG.svg";
import heroBg1Svg from "../../assets/HeroBg1Svg.svg";

export default function Hero() {
  const windowWidth =
    typeof window !== "undefined" ? document.documentElement.clientWidth : 0;
  const [showHeroSection2, setShowHeroSection2] = useState(false);
  const [showBgItem, setShowBgItem] = useState(false);
  const [showBgItem1, setShowBgItem1] = useState(true);

  useEffect(() => {
    // Show bgitem after a 1s delay
    const bgItemTimer = setTimeout(() => setShowBgItem(true), 1000);

    // Show bgitem1 after bgitem animation is finished (5s)
    const bgItem1Timer = setTimeout(() => setShowBgItem1(true), 6000);

    // Show hero2 after both bgitem1 and bgitem animations are finished
    const hero2Timer = setTimeout(() => setShowHeroSection2(true), 6000);

    return () => {
      clearTimeout(bgItemTimer);
      clearTimeout(bgItem1Timer);
      clearTimeout(hero2Timer);
    };
  }, []);

  return (
    <div>
      <AnimatePresence>
        <motion.div
          key="hero1"
          className="sm:h-screen h-screen md:pt-[35vh] pt-[40vh] lg:pt-[40vh] bg-black pb-20 lg:pb-20 relative overflow-hidden
            color-change-animation lg:pt-[100px] 2xl:pt-[40vh] 3xl:pt-[40vh]"
          style={{ paddingTop: windowWidth >= 1800 ? "40vh" : "" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
          {showBgItem && <img src={heroBgSvg} alt="" className="bgitem" />}
          {/* {showBgItem1 && <img src={heroBgSvg} alt="" className="bgitem1" />} */}

          <motion.div
            initial={{ y: "100vh" }}
            animate={{ y: 0 }}
            transition={{ delay: 1, duration: 1 }}
          >
            <h1 className="font-bold text-4xl lg:text-8xl 2xl:mt-[0] lg:pt-35 text-center">
              Don't let <span style={{ color: "rgb(100,151,254)" }}>tech</span>
            </h1>
            <h1 className="font-light text-4xl lg:text-8xl text-center mb-5 lg:mb-12">
              <span>become a barrier!</span>
            </h1>
          </motion.div>

          <motion.div
            initial={{ y: "100vh" }}
            animate={{ y: 0 }}
            transition={{ delay: 2.3, duration: 1 }}
          >
            <p className="w-full mx-auto px-4 md:pr-[40px] md:pl-[40px] text-center font-normal text-base md:text-3xl md:block lg:text-3xl lg:block">
              We leverage AI as an enabler, building advanced tech that brings
              your ideas to life.
            </p>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
