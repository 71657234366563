import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import rocket1 from "../../assets/img/rocket1.png";
import enterpriseImg from "../../assets/img/enterpriseimg.png";
import rightArrow from "../../assets/svg/rightArrow.svg";
import leftArrow from "../../assets/svg/leftArrow.svg";

const Info1 = () => {
  const windowWidth = document.documentElement.clientWidth;
  const founderRef = useRef(null);
  const enterpriseRef = useRef(null);
  const founderControl = useAnimation();
  const enterpriseControl = useAnimation();

  const isInViewFounder = useInView(founderRef, { triggerOnce: true });
  const isInViewEnterprise = useInView(enterpriseRef, { triggerOnce: true });

  React.useEffect(() => {
    if (isInViewFounder) {
      founderControl.start({
        scaleY: 1,
        transition: { duration: 1 },
      });
    }
  }, [isInViewFounder, founderControl]);

  useEffect(() => {
    if (isInViewEnterprise) {
      enterpriseControl.start({
        scaleY: 1,
        transition: { duration: 1 },
      });
    }
  }, [enterpriseControl, isInViewEnterprise]);

  return (
    <div className=" mx-auto  md:mt-[40px]">
      <div
        className="md:mt-[40px] mx-[7px] md:my-[0px] lg:[60px] flex items-center justify-center
       flex-wrap-reverse sm:gap-[50px] md:gap-[100px] lg:gap-[120px] xl:gap-[150px] 2xl:gap-[250px]
        md:pt-[0px]  "
        style={{ paddingTop: windowWidth >= 1800 ? "170px" : "" }}
      >
        <div
          className="w-full  flex item-center justify-center md:w-[25vw] lg:w-[21vw]
         xl:w-[29vw] relative "
          style={{ width: windowWidth >= 1800 ? "35vw" : "" }}
        >
          <motion.div
            ref={founderRef}
            initial={{ scaleY: 0 }}
            animate={founderControl}
            style={{
              display: "block",
              margin: "0",
              width: "100%",
              transformOrigin: "bottom center mx-2 box-border",
            }}
          >
            <div
              className="relative bg-[#F9F9F9] h-[37vh] md:h-[30vh] lg:h-[33vh] xl:h-[34vh] 2xl:h-[45vh] 2xl:w-full
              rounded-[20px]   md:overflow-visible flex item-center justify-center 2xl:ml-[40px]"
              style={{ height: windowWidth >= 1800 ? "44vh" : "" }}
            >
              <img
                src={rightArrow}
                alt="rightArrow"
                className=" w-[5vw] absolute md:top-[115px]  
                 lg:top-[80px]  right-[-4vh] lg:right-[-6vh]  xl:top-[16vh]  xl:right-[-11vh] 2xl:right-[-10vh]  hidden md:block"
              />
              <img
                src={rocket1}
                alt="rocket1"
                className=" h-[33vh]  md:left-[0px] lg:w-[7vw] lg:h-[25vh]  lg:left-[60px] xl:h-[28vh] xl:w-[11vw]
                   xl:left-[90px] md:w-[17vw] md:h-[25vh] 2xl:w-[20vh] 2xl:h-[40vh] md:mt-[5px] "
                style={{
                  transform: "rotate(0deg)",
                  mixBlendMode: "luminosity",
                  width: windowWidth >= 1800 ? "[47vw]" : "",
                  // top: windowWidth >= 1800 ? "-170px" : "",
                  // left: windowWidth >= 1800 ? "60px" : "",
                  // botttom: windowWidth >= 1800 ? "50px" : "",
                }}
              />
            </div>
          </motion.div>
          <div
            className=" w-full h-[7vh] md:h-[75px] lg:h-[80px] xl:h-[10vh] flex items-center
           lg:text-[30px] xl:text-[36px] justify-center bg-[#538CFF] text-[#FFFFFF]
            font-bold text-[23px] text-center rounded-b-[20px] absolute bottom-0 2xl:ml-[75px]"
          >
            For Start-ups
          </div>
        </div>
        <div>
          <div
            className="font-semibold text-[#0F0F0F] text-center md:text-left
           text-[20px] lg:text-[35px] xl:text-[38px]  leading-[48px]"
          >
            Your Tech Co-Founder
          </div>
          <div
            className=" text-[#636363] text-[15px] lg:text-[20px] xl:text-[24px]
           leading-[30px] mt-[12px] md:w-[40vw] text-center md:text-left
           lg:w-[35vw] xl:w-[47vw]
           "
          >
            From Idea to Life, we're your partner for all things tech. We
            provide the expertise and execution to get your MVP to market
            faster.
          </div>
          <div
            className="text-[15px] lg:text-[20px] xl:text-[24px] font-semibold
           text-center md:text-left text-[#636363] leading-[30px] my-[12px] "
          >
            World class product at an industry first ‘cost + equity’ model
          </div>
          {/* <div className="mb-[14px] md:mb-[30px] text-center md:text-left md:text-[15px] lg:text-[20px] xl:text-[24px] text-[#0F0F0F]">
            Idea, GTM Strategy, Tech Umm!?
      
          </div>
          <div
            className=" px-6 sm:px-0 md:w-[40vw] text-center md:text-left lg:w-[35vw] xl:w-[47vw] mb-[100px] md:mb-[0px] 
            text-[20px] lg:text-[35px] xl:text-[38px] text-[#0F0F0F] font-semibold leading-5 lg:leading-[50px]"
          >
            We get your MVP to market faster than ever before.
          </div> */}
        </div>
      </div>
      <div
        className="w-full mt-[50px] flex justify-center  items-center flex-wrap sm:gap-[50px]
       md:gap-[100px] lg:gap-[120px]  xl:gap-[150px] 2xl:gap-[190px] "
      >
        <div>
          <div
            className="font-semibold text-[#0F0F0F] text-[20px] text-center 
          md:text-left lg:text-[35px] xl:text-[38px]  leading-[48px]"
          >
            Your AI Transformation Partner
          </div>
          <div
            className=" text-[#636363] text-[15px] lg:text-[20px] xl:text-[24px]
           leading-[30px] mt-[12px] md:w-[40vw] text-center md:text-left
           lg:w-[35vw] xl:w-[47vw]
           "
          >
            Drive efficiency and innovation with our custom AI and machine
            learning implementations. We empower you with Gen AI infusion to
            make your technology future proof .
          </div>
          <div
            className="text-[15px] lg:text-[20px] xl:text-[24px] font-semibold text-center md:text-left
           text-[#636363] leading-[30px] my-[12px] "
          >
            Secure & fast implementation of ‘agentic AI’ workflows
          </div>
          {/* <div
            className="mb-[14px]  lg:mb-[30px] text-center md:text-left md:text-[15px] lg:text-[20px]
           xl:text-[24px] text-[#0F0F0F]"
          >
            Proven Business. Check New-age UX?
           
          </div>
          <div
            className=" px-6  sm:px-0 mb-[70px] md:w-[40vw] lg:w-[35vw] xl:w-[48vw]  text-[20px] md:text-[20px] lg:text-[35px] xl:text-[40px]
           text-[#0F0F0F] font-semibold  leading-5 lg:leading-[50px] text-center md:text-left"
          >
            We implement technologies to fuel your growth.
          </div> */}
          {/* <div
            className=" mb-[5px] md:mb-[0px] md:text-[20px]  lg:text-[35px] xl:text-[40px]
           text-[#0F0F0F] font-semibold leading-5 md:leading-[50px] text-center"
          >
            {" "}
            
          </div> */}
        </div>
        <div
          className="w-full mx-2  h-[37vh]  md:w-[25vw]  xl:w-[25vw] xl:h-[32vh] relative  lg:w-[26vw] lg:h-[45vh]  2xl:h-[45vh]    "
          style={{ width: windowWidth >= 1800 ? "30vw" : "" }}
        >
          <motion.div
            ref={enterpriseRef}
            initial={{ scaleY: 0 }}
            animate={enterpriseControl}
            style={{
              display: "block",
              margin: "0 ",
              width: "100%",
              transformOrigin: "bottom center ", // Set transform origin to bottom center
              transform: "rotate(0deg)",
              mixBlendMode: "luminosity",
            }}
          >
            <div
              className="bg-[#F9F9F9] md:w-full h-[30vh] md:h-[27vh] lg:h-[33vh] xl:h-[23vh]  flex justify-center item-center
             rounded-[20px] "
              style={{ height: windowWidth >= 1800 ? "44vh" : "" }}
            >
              <img
                src={leftArrow}
                alt="leftArrow"
                className="w-[5vw] absolute md:top-[75px] md:left-[-40px] lg:top-[110px] lg:left-[-50px]
                 xl:top-[105px] 2xl:left-[-80px]  xl:left-[-60px] left-[-95px] hidden md:block"
                style={{
                  top: windowWidth >= 1800 ? "150px" : "",
                }}
              />
              <img
                src={enterpriseImg}
                alt="founder"
                className=" w-full h-[41vh] xl:h-[30vh] 2xl:h-[50vh] lg:h-[45vh]"
                style={{
                  width: windowWidth >= 1800 ? "20vw" : "",
                  // top: windowWidth >= 1800 ? "-170px" : "",
                  // left: windowWidth >= 1800 ? "60px" : "",
                }}
              />
            </div>
          </motion.div>
          <div
            className=" w-full h-[7vh] md:h-[70px] lg:h-[80px] xl:h-[10vh] flex items-center
           lg:text-[30px] xl:text-[36px] justify-center bg-[#538CFF] text-[#FFFFFF]
            font-bold text-[23px] text-center rounded-b-[20px] absolute bottom-0 left-0 right-0 "
          >
            For Enterprises
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info1;
