import React from "react";
import Members from "../components/Teams/Members";
import GoaTour from "../components/Teams/goa";
import Contact from "../components/home/Contact";
import { Helmet } from "react-helmet";
const Teams = () => {
  return (
    <>
      <Helmet>
        <title>
          Meet the Es Magico Team: Your Digital Transformation Experts
        </title>
        <meta
          name="description"
          content="Learn about our talented team of AI experts, developers, designers, and strategists who are passionate about driving digital innovation."
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Meet the Es Magico Team: Your Digital Transformation Experts"
        />
        <meta
          property="og:description"
          content="Learn about our talented team of AI experts, developers, designers, and strategists who are passionate about driving digital innovation."
        />
      </Helmet>
      <div className="pt-[70px] lg:pt-[100px] ">
        <div className="bg-[#F7F7F7]">
          <div className="text-[28px] bg-[#F7F7F7] lg:text-[80px] m-auto text-center #000000 font-['Outfit'] max-w-[317px] lg:max-w-[715px] pt-[56px] lg:pt-[140px] pb-[44px] lg:pb-[101px] ">
            The <span className="font-bold"> Visionaries</span> of Es Magico
            Tech
          </div>

          <Members />
        </div>
        <GoaTour />
        <Contact />
      </div>
    </>
  );
};

export default Teams;
