import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import wizard from "../../assets/img/wizard.json";
import cauldron from "../../assets/img/cauldron.json";
import bubbles from "../../assets/img/bubbles.json";
import magicwand from "../../assets/img/magicwand.json";
import Mixing from "../../assets/img/Mixing.json";
import FinalOutput from "../../assets/img/FinalOutput.json";

const Speedsection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 6);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const data = [
    {
      animation: wizard,
      title: "Research",
      desc: "Gather the perfect ingredients.",
    },
    {
      animation: cauldron,
      title: "Architect",
      desc: "Create the best recipe",
    },
    {
      animation: bubbles,
      title: "Innovate",
      desc: "Add a dash of creativity.",
    },
    { animation: magicwand, title: "Create", desc: "Mix it all together." },
    {
      animation: Mixing,
      title: "Delight",
      desc: "Taste the refreshing outcome.",
    },
    {
      animation: FinalOutput,
      title: "Improvise",
      desc: "Perfect the recipe for future ",
    },
  ];

  return (
    <div className="w-full  mx-auto mt-[20px] mb-[42px] relative overflow-hidden">
      <div className="blue-line"></div>
      <p
        className="h-[8vh] text-Outfit text-[24px]
       md:text-[38px] font-semibold mt-[35px] md:mt-[65px] 
       leading-[25.2px] ml-[28.8px] mr-[26px] md:ml-[50px] 
       md:mr-[50px] md:mb-[50px] md:leading-[47.88px] text-[#0F0F0F] 
       lg:text-5xl text-center md:block"
      >
        Speed and Excellence: The Es Magico Brew
      </p>
      <div
        className="grid gap-[22px] md:flex-row mt-[35px] md:mt-[107px]
       md:gap-[23px] md:flex justify-center md:justify-between items-center
        border-b-[#B3B3B3] border-b border-solid mx-[26px]  md:mx-[80px]"
      >
        {data.map((item, index) => (
          <div
            key={index}
            className={` relative
                 flex md:flex-wrap  gap-[8px]  
                 pb-[38px]
                 ${activeIndex === index ? "active " : ""}`}
          >
            <div
              className={`${
                activeIndex === index ? "bg-[#E5ECFA]" : ""
              } p-[14px] w-[100%] rounded-[12px] `}
            >
              <div
                className={`w-[45px] flex justify-start  ${
                  activeIndex === index ? "animate-popup" : "icon-inactive"
                }`}
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: item.animation,
                  }}
                  height="90%"
                  width="90%"
                  opacity={15}
                />
              </div>
              <div className="flex-wrap text-content mt-[8px]">
                <p
                  className="font-Outfit text-[18px] font-semibold 
                leading-[22.68px] text-[#0F0F0F]"
                >
                  {item.title}
                </p>
                <p
                  className="whitespace-nowrap font-Outfit text-[14px]
                 font-normal leading-[19.6px] text-[#0F0F0F] mt-[8px]"
                >
                  {item.desc}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        @keyframes popup {
          0% {
            transform: scale(1);
            opacity: 2;
          }
          50% {
            transform: scale(1.2);
            opacity: 2;
          }
          100% {
            transform: scale(1);
            opacity: 2;
          }
        }

        .animate-popup {
          animation: popup 2s ease-in-out;
        }

        .icon-inactive {
          opacity: 5;
          transition: opacity 0.3s ease-in-out;
        }

        .text-content {
          color: #0f0f0f !important;
        }

        .active .text-content {
          z-index: 20;
          position: relative;
        }

        @media (min-width: 768px) {
          .blue-line {
            position: static;
            height: 2px;
            width: 50px;
            margin-left: 50px;
            margin-top: 10px;
            margin-bottom: 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default Speedsection;
