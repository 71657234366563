import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import cardBgIcon from "../assets/img/cardBgImg.png";
// import cardBgIconMobile from "../assets/img/cardBgIconMobile.png";

// import cardIcon from "../assets/svg/cardIcon.svg";
import blur1 from "../assets/svg/blur1.svg";
import blur2 from "../assets/svg/blur2.svg";
const TestimonialSlider = ({ testimonials }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const windowWidth = document.documentElement.clientWidth;
  const settings = {
    dots: true,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    centerPadding: "20%",
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnDotsHover: false,
    // rtl: true,
    centerMode: true,
    customPaging: (i) => (
      <div
        className={`w-[40px] h-[7px] rounded-[40px] mt-[50px] flex gap-[5px] ${
          currentSlide === i ? "bg-blue-500" : "bg-gray-300"
        }`}
      ></div>
    ),
    appendDots: (dots) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: "10px",
        }}
      >
        {dots}
      </div>
    ),
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "8%",
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "8%",
          arrows: false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "8%",
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="relative">
      <img
        src={blur1}
        alt="blur_img"
        className="absolute right-0 h-[100%] z-50"
      />
      <img
        src={blur2}
        alt="blur_img"
        className="absolute left-0 h-[100%] z-50"
      />
      <Slider {...settings} classNam="bgGradient">
        {testimonials?.map((item, index) => {
          return (
            <>
              <div key={index} className="px-[10px] md:px-[24px]" >
                <img
                  src={item.img}
                  alt="img"
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
                {/* {windowWidth > 600 ? (
                  <img
                    className="absolute z-[-1]  right-0 top-0 bottom-0 h-[100%] max-w-none "
                    src={cardBgIcon}
                    alt="Background"
                  />
                ) : (
                  <img
                    className="absolute z-[-1]  left-0 right-0 bottom-0 w-[100%]"
                    src={cardBgIconMobile}
                    alt="Background"
                  />
                )}

                <div
                  className={`sm:w-[70%]   ${windowWidth > 600 ? "h-[100%]" : ""
                    } `}
                >
                  <div>
                    <img src={cardIcon} alt="Icon" className="max-w-full  " />
                  </div>
                  <div className="text-[15px] md:text-[18px] lg:text-[20px] 2xl:text-[24px] text-[#272727] text-justify leading-[24px] md:leading-[32px] mt-[20px] md:mt-[10px] lg:mt-[20px] ">
                    {item.message}
                  </div>
                </div>
                <div className=" sm:w-[30%] md:w-[27vw] lg:w-[20vw] flex flex-col justify-center ">
                  <div className=" w-[12vw] overflow-hidden m-auto  ">
                    <img src={item.image} alt="Profile" className="w-full object-cover" />
                  </div>
                  <div className="text-[16px] md:text-[24px] font-semibold text-[#272727] text-center ">
                    {item.name}
                  </div>
                  <div className=" text-[12px] md:text-[15px] text-[#0F0F0F] mt-[6px] text-center  ">
                    {item.designation}
                  </div>
                  <div className=" flex justify-center ">
                    <img src={item.companyLogo} alt="Company Logo" className="h-[4vh] lg:h-[28px] max-w-full " />
                  </div>
                </div> */}
              </div>
            </>
          );
        })}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
