import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import EsMagico1 from "../assets/EsMagico1.png";
import { motion } from "framer-motion";
import menu from "../assets/svg/span.svg";
import { FaLocationArrow } from "react-icons/fa";
import { RiArrowUpSLine } from "react-icons/ri";
import { RiArrowDownSLine } from "react-icons/ri";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [showBackground, setShowBackground] = useState(false);
  const [textColor, setTextColor] = useState(false);
  const { pathname } = useLocation();
  const topRef = useRef(null); // Define the ref
  const [showPopover, setShowPopover] = useState("");
  const navigate = useNavigate();
  const handleHover = (state) => {
    setShowPopover(state);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pathname]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBackground(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTextColor(true);
    }, 4500);
    return () => clearTimeout(timer);
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const screenWidth = window.innerWidth;
  const getWidth = () => {
    if (screenWidth <= 370) {
      return "35vw"; // Width for very small screens
    } else if (screenWidth <= 480) {
      return "30vw"; // Width for small screens
    } else if (screenWidth <= 768) {
      return "20vw"; // Width for tablets
    } else if (screenWidth >= 1024) {
      return "13vw"; // Width for larger screens
    } else {
      return "20vw"; // Default width
    }
  };

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0, color: "black" }}
      transition={{ delay: 1, duration: 0.4 }}
      className={`${
        visible ? "visible" : "hidden"
      } z-100 md:flex-row md:gap-[50px] lg:gap-[0px]`}
      style={{ backgroundColor: showBackground ? "#FFFFFF" : "" }}
    >
      {/* Title */}
      <Link to="/" onClick={handleClose}>
        <img src={EsMagico1} alt="Logo" style={{ width: getWidth() }} />
      </Link>
      {/* Hamburger Menu */}
      <div
        className="lg:hidden cursor-pointer absolute top-5 right-5"
        onClick={() => setMenuOpen((prev) => !prev)}
      >
        <img src={menu} alt="Menu" />
      </div>

      {/* Overlay */}
      {menuOpen && (
        <div className="fixed top-0 left-0 w-full h-screen bg-black opacity-50 z-30"></div>
      )}

      {/* Full-Screen Menu for Mobile */}
      <motion.div
        initial={{ opacity: 0, x: "-100%" }}
        animate={{ opacity: menuOpen ? 1 : 0, x: menuOpen ? "0%" : "-100%" }}
        className={`fixed top-0 left-0 w-full h-screen bg-white z-40 flex flex-col items-center justify-center  lg:hidden`}
        style={{ backgroundColor: showBackground ? "#FFFFFF" : "" }}
      >
        <button
          className="absolute top-5 right-5 text-5xl"
          onClick={handleClose}
        >
          &times;
        </button>
        <ul className="flex flex-col items-center space-y-8 text-[40px]">
          <li>
            <NavLink
              className="text-[40px]"
              onClick={handleClose}
              to="/OurExpertise"
            >
              Our Expertise
            </NavLink>
          </li>
          <li>
            <NavLink
              className="text-[40px]"
              onClick={handleClose}
              to="/AIProducts"
            >
              AI Products
            </NavLink>
          </li>
          <li>
            <NavLink
              className="text-[40px]"
              onClick={handleClose}
              to="/Portfolio"
            >
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink
              className="text-[40px]"
              onClick={handleClose}
              to="/Magicians"
            >
              Magicians
            </NavLink>
          </li>
        </ul>
      </motion.div>

      {/* Menu for Larger Screens */}
      <div
        className="hidden font-bold lg:flex items-center md:space-x-2 md:text-base 
       lg:space-x-8 xl:space-x-10  2xl:space-x-6 text-[8px]  md:text-[18px]"
      >
        {/* <NavLink onClick={handleClose} to="/services">
          
        </NavLink> */}

        <div
          className="relative"
          onMouseEnter={() => handleHover("OurExpertise")}
          onMouseLeave={() => handleHover("")}
        >
          <NavLink
            to="/OurExpertise"
            className="hover:text-blue-400 flex gap-[4px] md:gap-[8px] items-center"
          >
            Our Expertise{" "}
            {showPopover === "OurExpertise" ? (
              <RiArrowUpSLine size={20} />
            ) : (
              <RiArrowDownSLine size={20} />
            )}
          </NavLink>

          {/* Popover for Services Route */}
          {showPopover === "OurExpertise" && (
            <div
              className="absolute left-0 cursor-pointer w-[414px] text-[16px]   
            font-medium leading-[20px]  bg-white
             text-black p-4 rounded-[18px] shadow-lg"
            >
              <div className="mb-[24px]">AI & ML</div>
              <div className="mb-[24px]">Engineering</div>
              <div className="mb-[24px]">Product & UX</div>
              <div>Tech Stacks</div>
            </div>
          )}
        </div>

        {/* <NavLink  to="/products">
          
        </NavLink> */}

        <div
          className="relative"
          onMouseEnter={() => handleHover("products")}
          onMouseLeave={() => handleHover("")}
        >
          <NavLink
            to="/AIProducts"
            onClick={handleClose}
            className="hover:text-blue-400 flex gap-[4px]  md:gap-[8px] items-center"
          >
            AI Products{" "}
            {showPopover === "products" ? (
              <RiArrowUpSLine size={20} />
            ) : (
              <RiArrowDownSLine size={20} />
            )}
          </NavLink>

          {/* Popover for Services Route */}
          {showPopover === "products" && (
            <div
              className="absolute left-0  w-[414px] text-[16px]   
            font-medium leading-[20px]  bg-white
             text-black p-4 rounded-[18px] shadow-lg cursor-pointer"
            >
              <div className="mb-[24px]">PYZO</div>
              <div
                onClick={() => navigate("/AIProducts/gratify")}
                className="mb-[24px]"
              >
                Gratify
              </div>
              <div className="mb-[24px]">Sahay</div>
              <div>Workfly</div>
            </div>
          )}
        </div>

        <NavLink onClick={handleClose} to="/Portfolio">
          Portfolio
        </NavLink>
        <NavLink onClick={handleClose} to="/Magicians">
          Magicians
        </NavLink>

        <li
          className={`bg-black ${
            textColor ? "text-white" : "text-black"
          } rounded-3xl flex p-6 items-center gap-[5px] p-2 justify-center py-[5px] px-8`}
        >
          <button
            className="font-15px"
            onClick={() => window.open("http://wa.me/919468644446", "_blank")}
          >
            Book a Call
          </button>
          <FaLocationArrow
            size={20}
            color={textColor ? "#FFFFFF" : "#000000"}
            style={{ marginLeft: "10px" }}
          />
        </li>
      </div>
    </motion.nav>
  );
};
