import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Services from "../pages/Services";
import Products from "../pages/Products";
import Gratify from "../pages/Gratify";
import Esto from "../pages/Esto";
import Work from "../pages/Work";
import Teams from "../pages/Teams";

import Detailpage from "./Work/Detailpage";
import Portfoliodetailpage from "./Work/Portfoliodetailpage";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/OurExpertise" element={<Services />}></Route>
      <Route path="/AIProducts" element={<Products />}></Route>
      <Route path="/AIProducts/gratify" element={<Gratify />}></Route>
      <Route path="/AIProducts/Esto" element={<Esto />}></Route>
      <Route path="/Portfolio" element={<Work />}></Route>
      <Route path="/Magicians" element={<Teams />}></Route>
      <Route path="/Portfolio/Detailpage" element={<Detailpage />}></Route>
      <Route
        path="/Portfolio/Portfoliodetailpage"
        element={<Portfoliodetailpage />}
      ></Route>
    </Routes>
  );
};

export default AllRoutes;
