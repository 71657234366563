// // import React, { useState } from "react";
// // import { isValidForm } from "../../utils/validation";
// // import PhoneNumberInput from "../../uicomponents/PhoneNumberInput";
// // import Create1 from "../../assets/img/Create1.png";
// // const Contact = () => {
// //   const [values, setValues] = useState({
// //     requirement: "",
// //     name: "",
// //     email: "",
// //     phone: "",
// //   });
// //   const [error, setError] = useState({
// //     requirement: "",
// //     name: "",
// //     email: "",
// //     phone: "",
// //   });

// //   const handleChange = (key, value) => {
// //     if (error[key]) {
// //       setError((pre) => {
// //         return {
// //           ...pre,
// //           [key]: "",
// //         };
// //       });
// //     }
// //     setValues((pre) => {
// //       return {
// //         ...pre,
// //         [key]: value,
// //       };
// //     });
// //   };

// //   const handleSubmit = () => {
// //     if (!isValidForm(values, setError)) {
// //       console.log("form submitted", values);
// //       // alert("form submitted");
// //     }
// //   };

// //   return (
// //     <div className="bg-[#538CFF]	p-5 lg:p-10  my-10 ">
// //       <div className="w-full text-center mb-8 lg:mb-12 flex flex-col justify-center items-center">
// //         <img
// //           src={Create1}
// //           alt="create"
// //           className="h-[9vh] mb-4"
// //         />
// //         <span className="text-[#FFFFFF] text-2xl lg:text-5xl font-Outfit break-words">
// //           Building tech is hard, but with us, it's Magic
// //         </span>
// //       </div>

// //       <div className="">
// //         <div className="w-full  md:mt-[72px] flex flex-wrap gap-6 lg:gap-14 justify-center items-center mb-6">
// //           {/* <div className="relative  "> */}
// //           {/* <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2 mt-[-6px] border-white lg:max-w-[460px]">
// //             <select
// //               className="block bg-[#5A91FF] text-[#FFFFFF] w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px]
// //                 lg:mb-2 cursor-pointer text-[18px]   border-white active:outline-none
// //                 focus:outline-none focus:border-none focus:ring-0 active:border-none  "
// //               value={values.requirement}
// //               onChange={(e) => handleChange("requirement", e.target.value)}
// //             >
// //               <option value="" disabled hidden>
// //                 I Want To
// //               </option>
// //               <option value="build-a-product">Build a Product</option>
// //               <option value="get-hired">Get Hired</option>
// //               <option value="leverage-saas">Leverage SaaS</option>
// //             </select>
// //             {error.requirement && (
// //               <div className="text-[#FFFFFF] text-[12px]">
// //                 {error.requirement}
// //               </div>
// //             )}
// //           </div> */}

// //           {/* <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2  border-white lg:max-w-[460px]">
// //             <select
// //               className="block bg-[#5A91FF] text-white w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px] lg:mb-2 cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
// //               value={values.requirement}
// //               onChange={(e) => handleChange("requirement", e.target.value)}
// //               style={{
// //                 color: values.requirement ? "#FFFFFF" : "#000000",
// //                 backgroundColor: "#5A91FF",
// //               }}
// //             >
// //               <option value="" disabled hidden style={{ text: "#FFFFFF" }}>
// //                 I Want To
// //               </option>
// //               <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Build a Product
// //               </option>
// //               <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Get Hired
// //               </option>
// //               <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Leverage SaaS
// //               </option>
// //               <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Consult an Expert
// //               </option>
// //               <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Leverage AI Solutions
// //               </option>
// //             </select>
// //             {error.requirement && (
// //               <div className="text-[#FFFFFF] text-[12px]">
// //                 {error.requirement}
// //               </div>
// //             )}
// //           </div> */}
// //           <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2 mt-[-6px] border-white lg:max-w-[460px]">
// //             <select
// //               className="block bg-[#5A91FF] text-white w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px] lg:mb-2 cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
// //               value={values.requirement}
// //               onChange={(e) => handleChange("requirement", e.target.value)}
// //               style={{
// //                 backgroundColor: "#5A91FF",
// //                 color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF", // Ensure color is applied correctly
// //               }}
// //             >
// //               <option
// //                 value=""
// //                 disabled
// //                 hidden
// //                 style={{
// //                   color: "#FFFFFF", // Ensure placeholder text is white
// //                 }}
// //               >
// //                 I Want To
// //               </option>
// //               <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Build a Product
// //               </option>
// //               <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Get Hired
// //               </option>
// //               <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Leverage SaaS
// //               </option>
// //               <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Consult an Expert
// //               </option>
// //               <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Leverage AI Solutions
// //               </option>
// //             </select>
// //             {error.requirement && (
// //               <div className="text-[#FFFFFF] text-[12px]">
// //                 {error.requirement}
// //               </div>
// //             )}
// //           </div>

// //           {/* </div> */}
// //           {/* <div className="w-[100%] lg:max-w-[460px]">
// //             <input
// //               className="block bg-[#5A91FF] text-[#FFFFFF] placeholder:text-[#FFFFFF]
// //               text-[18px] w-[100%] lg:w-[460px] py-[14px] px-[14px] lg:py-[18px] lg:px-[14px]
// //                  lg:mb-2 rounded-md  border-white border-b-2 active:outline-none
// //                 focus:outline-none focus:border-b-2 focus:ring-0 active:border-none
// //                  placeholder-gray-900"
// //               type="text"
// //               placeholder="Name"
// //               style={{ outline: "none" }}
// //               value={values.name}
// //               onChange={(e) => handleChange("name", e.target.value)}
// //             />
// //             {error.name && (
// //               <div className="text-[#FFFFFF] text-[12px]">{error.name}</div>
// //             )}
// //           </div> */}
// //           {/* <div className="w-[100%] lg:max-w-[460px]">
// //             <input
// //               className="block active:outline-none focus:outline-none w-[100%]
// //               text-[18px]
// //                lg:w-[460px] focus:border-b-2 focus:ring-0 active:border-none
// //                  bg-[#5A91FF] text-[#FFFFFF] placeholder:text-[#FFFFFF]
// //                   py-[14px] px-[14px] lg:py-[18px] lg:px-[14px]  lg:mb-2  rounded-md
// //                     border-white border-b-2 "
// //               type="email"
// //               placeholder="Email Address"
// //               value={values.email}
// //               onChange={(e) => handleChange("email", e.target.value)}
// //             />
// //             {error?.email && (
// //               <div className="text-[#FFFFFF] text-[12px]">{error?.email}</div>
// //             )}
// //           </div> */}
// //           <div className="w-[100%] lg:max-w-[460px]">
// //             <PhoneNumberInput
// //               value={values.phone}
// //               onchange={(value) => handleChange("phone", value)}
// //             />
// //             {error?.phone && (
// //               <div className="text-[#FFFFFF] text-[12px] mt-[8px]">
// //                 {error?.phone}
// //               </div>
// //             )}
// //           </div>
// //         </div>

// //         <button
// //           onClick={handleSubmit}
// //           className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit
// //           text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF]
// //           rounded-3xl flex justify-center items-center hover:opacity-95 transition
// //           duration-300 ease-in-out m-auto gap-6"
// //         >
// //           Submit
// //         </button>
// //       </div>

// //       {showThankYou && (
// //         <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
// //           <div className="bg-white p-6 rounded-lg shadow-lg text-center">
// //             <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
// //             <p>Your submission has been received.</p>
// //           </div>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default Contact;

// import React, { useState } from "react";
// import PhoneNumberInput from "../../uicomponents/PhoneNumberInput";
// import Create1 from "../../assets/img/Create1.png";

// const Contact = () => {
//   const [values, setValues] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });
//   const [error, setError] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });
//   const [showThankYou, setShowThankYou] = useState(false);

//   const handleChange = (key, value) => {
//     if (error[key]) {
//       setError((prev) => ({
//         ...prev,
//         [key]: "",
//       }));
//     }
//     setValues((prev) => ({
//       ...prev,
//       [key]: value,
//     }));
//   };

//   const handleSubmit = () => {
//     let hasError = false;
//     const newError = { ...error };

//     // Check if phone number is filled
//     if (!values.phone) {
//       newError.phone = "Phone number is required.";
//       hasError = true;
//     } else {
//       newError.phone = "";
//     }

//     // Check if requirement is selected
//     if (!values.requirement) {
//       newError.requirement = "This field is required.";
//       hasError = true;
//     } else {
//       newError.requirement = "";
//     }

//     setError(newError);

//     if (!hasError) {
//       console.log("form submitted", values);
//       setShowThankYou(true);

//       // Reset form after submission
//       setValues({
//         requirement: "",
//         name: "",
//         email: "",
//         phone: "",
//       });

//       // Hide thank you message after 3 seconds
//       setTimeout(() => {
//         setShowThankYou(false);
//       }, 3000);
//     }
//   };

//   return (
//     <div className="bg-[#538CFF] p-5 lg:p-10 my-10 relative overflow-hidden">
//       <div className="w-full text-center mb-8 lg:mb-12 flex flex-col justify-center items-center">
//         <img
//           src={Create1}
//           alt="create"
//           className=" h-[5vh] xl:h-[6vh] mb-4"
//         />
//         <span className="text-[#FFFFFF] text-2xl lg:text-5xl font-Outfit break-words">
//           Building tech is hard, but with us, it's Magic
//         </span>
//       </div>

//       <div >
//         <div className="w-full md:mt-[72px] flex flex-wrap gap-6 justify-start items-start mb-6 pl-[30px]  ">
//           <div className="w-full lg:max-w-[460px] flex  item-center   ">
//             {/* <div className="bg-[#5A91FF] w-[90%] sm:w-[60%] lg:w-[400px]  rounded-md  border-b-2 border-white">
//               <select
//                 className="block  text-white  pr-[70px]  sm:pr-[170px] lg:pr-[170px]   m-auto py-[14px] cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0 rounded-3xl"
//                 value={values.requirement}
//                 onChange={(e) => handleChange("requirement", e.target.value)}
//                 style={{
//                   backgroundColor: "#5A91FF",
//                   color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF",
//                 }}
//               >
//                 <option
//                   value=""
//                   disabled
//                   hidden
//                   style={{
//                     color: "#FFFFFF",
//                   }}
//                 >
//                   I Want To
//                 </option>
//                 <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Build a Product
//                 </option>
//                 <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Get Hired
//                 </option>
//                 <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Leverage SaaS
//                 </option>
//                 <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Consult an Expert
//                 </option>
//                 <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Leverage AI Solutions
//                 </option>
//               </select>
//             </div> */}
//             <div className="bg-[#5A91FF] w-[90%] sm:w-[60%] md:w-[40%] md:pr-[90px] lg:w-[400px] rounded-md border-b-2 border-white">
//               <select
//                 className="block text-white  m-auto py-[14px] cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0 rounded-3xl"
//                 value={values.requirement}
//                 onChange={(e) => handleChange("requirement", e.target.value)}
//                 style={{
//                   backgroundColor: "#5A91FF",
//                   color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF",
//                   paddingRight: "70px",
//                 }}
//               >
//                 <option value="" disabled hidden style={{ color: "#FFFFFF" }}>
//                   I Want To
//                 </option>
//                 <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Build a Product
//                 </option>
//                 <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Get Hired
//                 </option>
//                 <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Leverage SaaS
//                 </option>
//                 <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Consult an Expert
//                 </option>
//                 <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Leverage AI Solutions
//                 </option>
//               </select>
//             </div>

//             <style jsx>{`
//   @media (max-width: 370px) {
//     select {
//       padding-right: 30px !important;
//     }
//   }
// `}</style>

//             {/* Display error message directly below the select */}
//             {error.requirement && (
//               <div className="text-[#FF0000] text-[12px] mt-1">
//                 {error.requirement}
//               </div>
//             )}
//           </div>

//           <div className="w-[90%] flex item-start  sm:w-[60%] md:w-[0%] lg:w-[400px]  ">
//             <PhoneNumberInput
//               value={values.phone}
//               onchange={(value) => handleChange("phone", value)}
//             />
//             {/* Display error message directly below the phone input */}
//             {error.phone && (
//               <div className="text-[#FF0000] text-[12px] mt-1">
//                 {error.phone}
//               </div>
//             )}
//           </div>
//         </div>

//         <button
//           onClick={handleSubmit}
//           className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit
//           text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF]
//           rounded-3xl flex justify-center items-center hover:opacity-95 transition
//           duration-300 ease-in-out m-auto gap-6"
//         >
//           Submit
//         </button>

//         {/* Thank you message placed below the submit button */}
//         {showThankYou && (
//           <div className="mt-4 text-center text-[#FFFFFF]  p-4 rounded-md">
//             Thank you for your submission!
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Contact;

// import React, { useState } from "react";
// import { isValidForm } from "../../utils/validation";
// import PhoneNumberInput from "../../uicomponents/PhoneNumberInput";
// import Create1 from "../../assets/img/Create1.png";
// const Contact = () => {
//   const [values, setValues] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });
//   const [error, setError] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });

//   const handleChange = (key, value) => {
//     if (error[key]) {
//       setError((pre) => {
//         return {
//           ...pre,
//           [key]: "",
//         };
//       });
//     }
//     setValues((pre) => {
//       return {
//         ...pre,
//         [key]: value,
//       };
//     });
//   };

//   const handleSubmit = () => {
//     if (!isValidForm(values, setError)) {
//       console.log("form submitted", values);
//       // alert("form submitted");
//     }
//   };

//   return (
//     <div className="bg-[#538CFF]	p-5 lg:p-10  my-10 ">
//       <div className="w-full text-center mb-8 lg:mb-12 flex flex-col justify-center items-center">
//         <img
//           src={Create1}
//           alt="create"
//           className="h-[9vh] mb-4"
//         />
//         <span className="text-[#FFFFFF] text-2xl lg:text-5xl font-Outfit break-words">
//           Building tech is hard, but with us, it's Magic
//         </span>
//       </div>

//       <div className="">
//         <div className="w-full  md:mt-[72px] flex flex-wrap gap-6 lg:gap-14 justify-center items-center mb-6">
//           {/* <div className="relative  "> */}
//           {/* <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2 mt-[-6px] border-white lg:max-w-[460px]">
//             <select
//               className="block bg-[#5A91FF] text-[#FFFFFF] w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px]
//                 lg:mb-2 cursor-pointer text-[18px]   border-white active:outline-none
//                 focus:outline-none focus:border-none focus:ring-0 active:border-none  "
//               value={values.requirement}
//               onChange={(e) => handleChange("requirement", e.target.value)}
//             >
//               <option value="" disabled hidden>
//                 I Want To
//               </option>
//               <option value="build-a-product">Build a Product</option>
//               <option value="get-hired">Get Hired</option>
//               <option value="leverage-saas">Leverage SaaS</option>
//             </select>
//             {error.requirement && (
//               <div className="text-[#FFFFFF] text-[12px]">
//                 {error.requirement}
//               </div>
//             )}
//           </div> */}

//           {/* <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2  border-white lg:max-w-[460px]">
//             <select
//               className="block bg-[#5A91FF] text-white w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px] lg:mb-2 cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
//               value={values.requirement}
//               onChange={(e) => handleChange("requirement", e.target.value)}
//               style={{
//                 color: values.requirement ? "#FFFFFF" : "#000000",
//                 backgroundColor: "#5A91FF",
//               }}
//             >
//               <option value="" disabled hidden style={{ text: "#FFFFFF" }}>
//                 I Want To
//               </option>
//               <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Build a Product
//               </option>
//               <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Get Hired
//               </option>
//               <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Leverage SaaS
//               </option>
//               <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Consult an Expert
//               </option>
//               <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Leverage AI Solutions
//               </option>
//             </select>
//             {error.requirement && (
//               <div className="text-[#FFFFFF] text-[12px]">
//                 {error.requirement}
//               </div>
//             )}
//           </div> */}
//           <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2 mt-[-6px] border-white lg:max-w-[460px]">
//             <select
//               className="block bg-[#5A91FF] text-white w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px] lg:mb-2 cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
//               value={values.requirement}
//               onChange={(e) => handleChange("requirement", e.target.value)}
//               style={{
//                 backgroundColor: "#5A91FF",
//                 color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF", // Ensure color is applied correctly
//               }}
//             >
//               <option
//                 value=""
//                 disabled
//                 hidden
//                 style={{
//                   color: "#FFFFFF", // Ensure placeholder text is white
//                 }}
//               >
//                 I Want To
//               </option>
//               <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Build a Product
//               </option>
//               <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Get Hired
//               </option>
//               <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Leverage SaaS
//               </option>
//               <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Consult an Expert
//               </option>
//               <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Leverage AI Solutions
//               </option>
//             </select>
//             {error.requirement && (
//               <div className="text-[#FFFFFF] text-[12px]">
//                 {error.requirement}
//               </div>
//             )}
//           </div>

//           {/* </div> */}
//           {/* <div className="w-[100%] lg:max-w-[460px]">
//             <input
//               className="block bg-[#5A91FF] text-[#FFFFFF] placeholder:text-[#FFFFFF]
//               text-[18px] w-[100%] lg:w-[460px] py-[14px] px-[14px] lg:py-[18px] lg:px-[14px]
//                  lg:mb-2 rounded-md  border-white border-b-2 active:outline-none
//                 focus:outline-none focus:border-b-2 focus:ring-0 active:border-none
//                  placeholder-gray-900"
//               type="text"
//               placeholder="Name"
//               style={{ outline: "none" }}
//               value={values.name}
//               onChange={(e) => handleChange("name", e.target.value)}
//             />
//             {error.name && (
//               <div className="text-[#FFFFFF] text-[12px]">{error.name}</div>
//             )}
//           </div> */}
//           {/* <div className="w-[100%] lg:max-w-[460px]">
//             <input
//               className="block active:outline-none focus:outline-none w-[100%]
//               text-[18px]
//                lg:w-[460px] focus:border-b-2 focus:ring-0 active:border-none
//                  bg-[#5A91FF] text-[#FFFFFF] placeholder:text-[#FFFFFF]
//                   py-[14px] px-[14px] lg:py-[18px] lg:px-[14px]  lg:mb-2  rounded-md
//                     border-white border-b-2 "
//               type="email"
//               placeholder="Email Address"
//               value={values.email}
//               onChange={(e) => handleChange("email", e.target.value)}
//             />
//             {error?.email && (
//               <div className="text-[#FFFFFF] text-[12px]">{error?.email}</div>
//             )}
//           </div> */}
//           <div className="w-[100%] lg:max-w-[460px]">
//             <PhoneNumberInput
//               value={values.phone}
//               onchange={(value) => handleChange("phone", value)}
//             />
//             {error?.phone && (
//               <div className="text-[#FFFFFF] text-[12px] mt-[8px]">
//                 {error?.phone}
//               </div>
//             )}
//           </div>
//         </div>

//         <button
//           onClick={handleSubmit}
//           className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit
//           text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF]
//           rounded-3xl flex justify-center items-center hover:opacity-95 transition
//           duration-300 ease-in-out m-auto gap-6"
//         >
//           Submit
//         </button>
//       </div>

//       {showThankYou && (
//         <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-lg shadow-lg text-center">
//             <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
//             <p>Your submission has been received.</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Contact;

// import React, { useState, useEffect } from "react";
// import PhoneNumberInput from "../../uicomponents/PhoneNumberInput";
// import Create1 from "../../assets/img/Create1.png";
// import emailjs from "emailjs-com";

// const Contact = () => {
//   const [values, setValues] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });
//   const [error, setError] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });
//   const [showThankYou, setShowThankYou] = useState(false);
//   const [isMagic, setIsMagic] = useState(true);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setIsMagic((prev) => !prev);
//     }, 3000);
//     return () => clearInterval(interval);
//   }, []);

//   const handleChange = (key, value) => {
//     if (error[key]) {
//       setError((prev) => ({
//         ...prev,
//         [key]: "",
//       }));
//     }
//     setValues((prev) => ({
//       ...prev,
//       [key]: value,
//     }));
//   };

//   const handleSubmit = () => {
//     let hasError = false;
//     const newError = { ...error };

//     // Validate phone
//     if (!values.phone) {
//       newError.phone = "Phone number is required.";
//       hasError = true;
//     } else {
//       newError.phone = "";
//     }

//     // Validate requirement
//     if (!values.requirement) {
//       newError.requirement = "This field is required.";
//       hasError = true;
//     } else {
//       newError.requirement = "";
//     }

//     setError(newError);

//     if (!hasError) {
//       emailjs
//         .send(
//           "service_vtkteyf", // replace with your service ID
//           "template_165m18h", // replace with your template ID
//           {
//             to_email: "shreyas@esmagico.in, akshat@esmagico.in",
//             requirement: values.requirement,
//             name: values.name,
//             email: values.email,
//             phone: values.phone,
//           },
//           "QiQE0Ir0iRvbbGqY-" // replace with your user ID
//         )
//         .then(
//           (response) => {
//             console.log("SUCCESS!", response.status, response.text);
//             setShowThankYou(true);

//             setValues({
//               requirement: "",
//               name: "",
//               email: "",
//               phone: "",
//             });

//             setTimeout(() => {
//               setShowThankYou(false);
//             }, 3000);
//           },
//           (error) => {
//             console.log("FAILED...", error);
//           }
//         );
//     }
//   };

//   return (
//     <div className="bg-[#538CFF] p-5 lg:p-10 my-10 relative overflow-x-hidden">
//       <div className="w-full text-center mb-8 lg:mb-12 flex flex-col justify-center items-center">
//         <img src={Create1} alt="create" className=" h-[5vh] xl:h-[6vh] mb-4" />
//         <span className="text-[#FFFFFF] text-2xl lg:text-5xl font-Outfit break-words">
//           Building tech is hard, but with us,{" "}
//           {isMagic ? "it's Magic" : "Es Magico"}
//         </span>
//       </div>

//       <div className="w-full md:mt-[72px] flex flex-wrap gap-6 lg:gap-14 justify-center items-center mb-6">
//         <div className="w-[100%] lg:max-w-[460px]">
//           <div className="bg-[#5A91FF] rounded-md border-b-2 border-white">
//             <select
//               className="block bg-[#5A91FF] text-white w-full pl-[14px] py-[18px] cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
//               value={values.requirement}
//               onChange={(e) => handleChange("requirement", e.target.value)}
//               style={{
//                 backgroundColor: "#5A91FF",
//                 color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF",
//               }}
//             >
//               <option
//                 value=""
//                 disabled
//                 hidden
//                 style={{
//                   color: "#FFFFFF",
//                 }}
//               >
//                 I Want To
//               </option>
//               <option
//                 value="build-a-product"
//                 style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
//               >
//                 Build a Product
//               </option>
//               <option
//                 value="get-hired"
//                 style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
//               >
//                 Get Hired
//               </option>
//               <option
//                 value="leverage-saas"
//                 style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
//               >
//                 Leverage SaaS
//               </option>
//               <option
//                 value="consult-expert"
//                 style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
//               >
//                 Consult an Expert
//               </option>
//               <option
//                 value="leverage-ai-solutions"
//                 style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
//               >
//                 Leverage AI Solutions
//               </option>
//             </select>
//           </div>
//           {error.requirement && (
//             <div className="text-[#FF0000] text-[12px] mt-1">
//               {error.requirement}
//             </div>
//           )}
//         </div>

//         <div className="w-[100%] lg:max-w-[460px]">
//           <PhoneNumberInput
//             value={values.phone}
//             onchange={(value) => handleChange("phone", value)}
//           />
//           {error.phone && (
//             <div className="text-[#FF0000] text-[12px] mt-1">{error.phone}</div>
//           )}
//         </div>
//       </div>

//       <button
//         onClick={handleSubmit}
//         className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit
//           text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF]
//           rounded-3xl flex justify-center items-center hover:opacity-95 transition
//           duration-300 ease-in-out m-auto gap-6"
//       >
//         Submit
//       </button>

//       {showThankYou && (
//         <div className="mt-4 text-center text-[#FFFFFF] p-4 rounded-md">
//           Thank you for your submission!
//         </div>
//       )}
//     </div>
//   );
// };

// export default Contact;

import React, { useState, useEffect } from "react";
import PhoneNumberInput from "../../uicomponents/PhoneNumberInput";
import Create1 from "../../assets/img/Create1.png";
import { motion, AnimatePresence } from "framer-motion";

const Contact = () => {
  const [values, setValues] = useState({
    requirement: "",
    name: "",
    email: "",
    remark: "",
    phone: "",
    role: "",
    resume: null,
  });

  const [error, setError] = useState({
    requirement: "",
    name: "",
    email: "",
    remark: "",
    phone: "",
    role: "",
    resume: "",
  });

  const [showThankYou, setShowThankYou] = useState(false);
  const [isMagic, setIsMagic] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Magic text effect
  useEffect(() => {
    const interval = setInterval(() => {
      setIsMagic((prev) => !prev);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // Replace with your actual Google Apps Script URL
  const scriptURL =
    "https://script.google.com/macros/s/AKfycbwM7kUjzzTI8YWasE0OVAVSmZx3na5oK-mujt3_tB2s0TM_RVN7PWFgvmUXl7f_KT3W/exec";

  const handleChange = (key, value) => {
    if (error[key]) {
      setError((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validateForm = () => {
    let hasError = false;
    const newError = { ...error };

    // Always required fields
    const requiredFields = ["requirement", "name", "email", "phone"];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        newError[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
        hasError = true;
      }
    });

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (values.email && !emailRegex.test(values.email)) {
      newError.email = "Please enter a valid email address.";
      hasError = true;
    }

    // Additional validations for "Get Hired"
    if (values.requirement === "get-hired") {
      if (!values.role) {
        newError.role = "Role is required when selecting Get Hired.";
        hasError = true;
      }
      if (!values.resume) {
        newError.resume = "Resume is required when selecting Get Hired.";
        hasError = true;
      }
    }

    setError(newError);
    return !hasError;
  };

  const handleSubmit = async () => {
    // Validate form
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      // Prepare form data for submission
      const formDataToSend = new FormData();

      // Append text fields
      Object.keys(values).forEach((key) => {
        if (key !== "resume" && values[key] !== null) {
          formDataToSend.append(key, values[key]);
        }
      });

      // Handle resume upload for "Get Hired"
      if (values.requirement === "get-hired" && values.resume) {
        // Convert resume to base64
        const base64Resume = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result.split(",")[1]);
          reader.readAsDataURL(values.resume);
        });

        // Append resume details
        formDataToSend.append("filename", values.resume.name);
        formDataToSend.append("mimetype", values.resume.type);
        formDataToSend.append("fileblob", base64Resume);
      }

      // Send to Google Apps Script
      const response = await fetch(scriptURL, {
        method: "POST",
        body: formDataToSend,
      });

      const result = await response.json();

      if (result.status != 500) {
        setShowThankYou(true);

        // Reset form
        setValues({
          requirement: "",
          name: "",
          email: "",
          phone: "",
          role: "",
          resume: null,
        });

        // Clear file input
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) fileInput.value = "";

        // Hide thank you message after 3 seconds
        setTimeout(() => {
          setShowThankYou(false);
        }, 3000);
      } else {
        // Handle error scenario
        console.error("Submission failed");
      }
    } catch (error) {
      console.error("Error!", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-[#538CFF]  px-[16px] lg:px-[240px] py-[28px] lg:py-[68px] my-10 relative overflow-x-hidden">
      <div className="w-full text-center mb-8 lg:mb-12 flex flex-col justify-center items-center">
        <img src={Create1} alt="create" className="h-[5vh] xl:h-[6vh] mb-4" />
        <div className="text-[#FFFFFF] text-2xl lg:text-5xl font-Outfit break-words">
          Building tech is hard, but with us,
          <AnimatePresence mode="wait">
            <motion.span
              key={isMagic ? "magic" : "magico"}
              className="w-[120px] lg:w-[250px] inline-block"
              initial={{ scale: 1.2, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{ duration: 0.6 }}
            >
              {isMagic ? "it's Magic" : "Es Magico"}
            </motion.span>
          </AnimatePresence>
          {/* <span
            className="w-[150px] lg:w-[250px] inline-block transition-all duration-500 ease-in-out transform"
            style={{ opacity: isMagic ? 1 : 0.7, transform: `scale(${isMagic ? 1 : 1.1})` }}
          >
            {isMagic ? "it's Magic" : "Es Magico"}
          </span> */}
        </div>
      </div>

      <div className="w-full md:mt-[72px] grid grid-cols-1 md:grid-cols-2  gap-6 lg:gap-14 justify-center items-center mb-[40px]">
        {/* Requirement Dropdown */}
        <div className="w-[100%] lg:max-w-[460px]">
          <div className="bg-[#5A91FF] rounded-md border-b-2 border-white">
            <select
              className="block bg-[#5A91FF] text-white w-full pl-[14px] py-[18px] cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
              value={values.requirement}
              onChange={(e) => handleChange("requirement", e.target.value)}
              style={{
                backgroundColor: "#5A91FF",
                color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF",
              }}
            >
              <option value="" disabled hidden>
                I Want To
              </option>
              <option value="build-a-product">Build a Product</option>
              <option value="get-hired">Get Hired</option>
              <option value="leverage-saas">Leverage SaaS</option>
              <option value="consult-expert">Consult an Expert</option>
              <option value="leverage-ai-solutions">
                Leverage AI Solutions
              </option>
            </select>
          </div>
          {error.requirement && (
            <div className="text-[#FF0000] text-[12px] mt-1">
              {error.requirement}
            </div>
          )}
        </div>

        {/* Phone Number Input */}
        <div className="w-[100%] lg:max-w-[460px]">
          <PhoneNumberInput
            value={values.phone}
            onchange={(value) => handleChange("phone", value)}
          />
          {error.phone && (
            <div className="text-[#FF0000] text-[12px] mt-1">{error.phone}</div>
          )}
        </div>

        {/* Name Input */}
        <div className="w-[100%] lg:max-w-[460px]">
          <input
            type="text"
            placeholder="Name"
            className="w-full p-[18px] text-[18px] placeholder-white border-b-2 border-white outline-none rounded-md bg-[#5A91FF] text-white"
            value={values.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          {error.name && (
            <div className="text-[#FF0000] text-[12px] mt-1">{error.name}</div>
          )}
        </div>

        {/* Email Input */}
        <div className="w-[100%] lg:max-w-[460px]">
          <input
            type="email"
            placeholder="Email"
            className="w-full p-[18px] text-[18px] rounded-md placeholder-white  border-b-2 border-white outline-none bg-[#5A91FF] text-white"
            value={values.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          {error.email && (
            <div className="text-[#FF0000] text-[12px] mt-1">{error.email}</div>
          )}
        </div>

        {/* Email Input */}
        <div className="w-[100%] lg:max-w-[460px]">
          <input
            type="text"
            placeholder="Remark"
            className="w-full p-[18px] text-[18px] rounded-md placeholder-white  border-b-2 border-white outline-none bg-[#5A91FF] text-white"
            value={values.remark}
            onChange={(e) => handleChange("remark", e.target.value)}
          />
          {error.remark && (
            <div className="text-[#FF0000] text-[12px] mt-1">
              {error.remark}
            </div>
          )}
        </div>

        {/* Additional Fields for Get Hired */}
        {values.requirement === "get-hired" && (
          <>
            {/* Role Input */}
            <div className="w-[100%] lg:max-w-[460px] ">
              <input
                type="text"
                placeholder="Role you want to get hired for"
                className="w-full p-[18px] outline-none text-[18px] placeholder-white border-b-2 border-white rounded-md bg-[#5A91FF] text-white"
                value={values.role}
                onChange={(e) => handleChange("role", e.target.value)}
              />
              {error.role && (
                <div className="text-[#FF0000] text-[12px] mt-1">
                  {error.role}
                </div>
              )}
            </div>

            {/* Resume Upload */}
            <div className="w-[100%] lg:max-w-[460px]">
              <input
                type="file"
                id="resume-upload"
                accept=".pdf"
                className="hidden"
                onChange={(e) => handleChange("resume", e.target.files[0])}
              />
              <label
                htmlFor="resume-upload"
                className="w-full p-[18px] text-[18px] rounded-md bg-white text-[#5A91FF] block cursor-pointer text-center"
              >
                {values.resume ? values.resume.name : " Upload Resume"}
              </label>
              {error.resume && (
                <div className="text-[#FF0000] text-[12px] mt-1">
                  {error.resume}
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {/* Submit Button */}
      <button
        onClick={handleSubmit}
        disabled={isSubmitting}
        className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit 
          text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF] 
          rounded-3xl flex justify-center items-center hover:opacity-95 transition 
          duration-300 ease-in-out m-auto gap-6"
      >
        {isSubmitting ? "Submitting..." : "Submit"}
      </button>

      {/* Thank You Message */}
      {showThankYou && (
        <div className="mt-4 text-center text-[#FFFFFF] p-4 rounded-md">
          Thank you for your submission!
        </div>
      )}
    </div>
  );
};

export default Contact;
