import React, { useEffect, useRef, useState } from "react";
import esto2 from "../../assets/img/esto2.png";
import pyzo2 from "../../assets/img/pyzo2.png";
import gratify2 from "../../assets/img/gratify2.png";
import Sahay from "../../assets/project/Sahay.png";
import AIInterviewer from "../../assets/project/AIInterviewer.png";
import pyzoBanner from "../../assets/svg/pyzoBanner.svg";
import gratifyBanner from "../../assets/svg/gratifyBanner.svg";
import sahayBanner from "../../assets/svg/sahayBanner.svg";
import lenaBanner from "../../assets/svg/LenaBanner.svg";
import { IoIosArrowRoundUp } from "react-icons/io";
const StackingpageshomeCard = ({
  title,
  description2,
  imageUrl,
  backgroundColor,
  index,
  banner,
  tableData,
}) => {
  const [cardHeight, setCardHeight] = useState("300px"); // Default height

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerWidth >= 1536) {
        setCardHeight("580px"); // 2xl screens
      } else if (window.innerWidth >= 1024) {
        setCardHeight("500px"); // Large screens
      } else if (window.innerWidth >= 768) {
        setCardHeight("350px"); // Medium screens
      } else {
        setCardHeight("440px"); // Small screens
      }
    };

    updateHeight(); // Set initial height
    window.addEventListener("resize", updateHeight); // Update height on resize

    return () => window.removeEventListener("resize", updateHeight); // Clean up listener
  }, []);

  return (
    <div
      className="sticky flex  justify-center   "
      style={{ top: `${index + 1}px`, opacity: 1, width: "100%" }}
    >
      {/* <img
        src={banner}
        alt="banner"
        className="object-contain w-full h-full"
        style={{
          borderRadius: "30px",
        }}
      /> */}
      <div
        className="p-5 shadow-[0_0_30px_0_rgba(0,0,0,0.3)] transition-all duration-300  text-white origin-top"
        style={{
          backgroundColor,
          borderRadius: "30px",
          // height: cardHeight, // Apply dynamic height
          width: "95%",
        }}
      >
        <div
          className="flex  flex-col justify-around   md:flex-row items-center md:gap-12 w-full md:mb-12 "
          style={{ top: `${(index + 1) * 10}px` }}
        >
          <div className="sm:w-2/3  xl:p-[100px] text-center md:text-start transition-all duration-300 text-white origin-top">
            <h1 className="font-['Outfit'] text-[14px] md:text-[16px] lg:text-[26px] font-semibold bg-transparent leading-6 md:leading-[32px] mb-4">
              {title}
            </h1>
            <p className="font-['Outfit'] text-[12px] md:text-[18px] leading-[22px] bg-transparent ">
              {description2}
            </p>
            <div className="flex gap-x-[48px] mt-[50px] lg:mt-[100px] justify-center">
              {tableData?.map((item) => {
                return (
                  <div className="border-b border-[#ECECEC] mb-[20px] w-[100%]">
                    <div
                      className="text-[12px] md:text-[24px] 
                    font-semibold leading-[30px] text-[#538CFF] flex items-center justify-center lg:justify-start"
                    >
                      {item.value}
                      {item?.arrow && (
                        <IoIosArrowRoundUp color="#34C759" size={24} />
                      )}
                    </div>
                    <div className="text-[#FFFFFF] text-center lg:text-left text-[10px] md:text-[14px] leading-[17px] my-[6px]">
                      {item.text}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="relative flex  w-2/3 sm:w-1/3 ">
            <img
              src={imageUrl}
              alt={title}
              className="  object-cover rounded-tl-lg "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CardsContainer = ({ cards }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const cards = containerRef.current.querySelectorAll(".sticky");
      const data = {
        Location: {},
        ToReduceBy: {},
      };

      cards.forEach((card, index) => {
        const stickyTop = parseInt(window.getComputedStyle(card).top);
        const currentTop = card.getBoundingClientRect().top - 200;
        data.Location[index] = currentTop - stickyTop;
        data.ToReduceBy[index] = 0;
      });

      for (let i = 0; i < cards.length; i++) {
        if (data.Location[i] <= 20) {
          for (let j = i; j > -1; j--) {
            data.ToReduceBy[j]++;
          }
        }
      }

      cards.forEach((card, index) => {
        const innerCard = card.firstElementChild;
        innerCard.style.transform = `scale(${
          1 - data.ToReduceBy[index] * 0.1
        })`;
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="flex  flex-col items-center justify-center"
      ref={containerRef}
    >
      {cards.map((card, index) => (
        <StackingpageshomeCard key={index} {...card} index={index} />
      ))}
    </div>
  );
};

const Stackingpageshome = () => {
  const cardsData = [
    {
      title: "Pyzo",
      description2:
        "PYZO modernizes application evaluation through its scalable, Gen-AI driven platform. Using a combination of open source models like Llama and LLMs like Gemini, It rapidly analyzes submissions, surfaces critical insights, and mitigates biases; facilitating a more efficient, data-backed, and seamless evaluation process",
      imageUrl: AIInterviewer,
      backgroundColor: "rgba(0,0,0)",
      tableData: [
        {
          value: "5000+",
          text: "Interviews Completed",
        },
        {
          value: "30000+",
          text: "Manhours Saved",
        },
        {
          value: "94%",
          text: "Score Accuracy",
          arrow: true,
        },
      ],
      banner: pyzoBanner,
    },
    {
      title: "Gratify",
      description2:
        "Gratify redefines customer loyalty by creating uniquely individualized experiences through its Gen AI-driven reward platform. Offering hyper-personalization and deep analytics, Gratify moves beyond generic loyalty programs, enabling brands to craft meaningful connections and track the success of targeted campaigns with precision.",
      imageUrl: gratify2,
      backgroundColor: "rgba(0,0,0)",
      tableData: [
        {
          value: "10L+",
          text: "Rewards Claimed",
        },
        {
          value: "₹1Cr+",
          text: "Rewards Credited",
        },
        {
          value: "5+",
          text: "Custom Games",
        },
        {
          value: "30%",
          text: "CSAT",
          arrow: true,
        },
      ],
      banner: gratifyBanner,
    },
    {
      title: "Sahay",
      description2:
        "SAHAY provides a best-in-class, RAG-powered solution for customer support, offering instant problem solving and engaging interactions. By understanding customer needs using advanced NLP techniques, it generates personalized responses that aim to resolve queries in a human way. SAHAY improves support outcomes and elevates the overall customer experience.",
      imageUrl: Sahay,
      backgroundColor: "rgba(0,0,0)",
      banner: sahayBanner,
      tableData: [
        {
          value: "80%",
          text: "Reduction in query TAT",
        },
        {
          value: "600+",
          text: "Queries Resolved",
        },
        {
          value: "45+",
          text: "Use Cases Supported",
        },
      ],
    },
    {
      title: "Workfly",
      description2:
        "Workfly is an innovative AI-driven lead management system engineered to transform sales performance. By automating lead capture, analyzing data points for accurate scoring, and streamlining follow-up, LENA helps teams focus on high-potential leads and execute strategic outreach for superior results.",
      imageUrl: esto2,
      backgroundColor: "rgba(0,0,0)",
      banner: lenaBanner,
      tableData: [
        {
          value: "80%",
          text: "Reduction in query TAT",
        },
        {
          value: "600+",
          text: "Queries Resolved",
        },
        {
          value: "45+",
          text: "Use Cases Supported",
        },
      ],
    },
    // {
    //     title: "AI Interviewer",
    //     description2: "Streamline your hiring process with our AI-powered interviewing solution.",
    //     imageUrl: AIInterviewer,
    //     backgroundColor: "rgba(0,0,0)",
    // }
  ];

  return (
    <div className="w-full md:mt-16">
      <div className="grid justify-center gap-4 overflow-hidden">
        <p
          className="text-xl md:text-3xl font-semibold leading-6 md:leading-9 text-center px-4 "
          style={{ color: "var(--Color-2, #0F0F0F)", opacity: "1" }}
        >
          Our B2B SaaS PRODUCTS
        </p>
        <p
          className="text-base md:text-[24px]  leading-6 md:leading-[33px`] text-center px-4 xl:px-[300px] mb-[20px] md:mb-[80px] "
          style={{ color: "var(--Color-2, #0F0F0F)", opacity: "0.6" }}
        >
          Integrate our unique SaaS solutions to boost your product capabilities
          and deliver exceptional value to your customers.
        </p>
      </div>
      <CardsContainer cards={cardsData} />
      <div className=" mx-3 px-12  text-center m-auto lg:m-0 md:top-[64px]  text-[20px] lg:text-[36px] font-semibold ">
        We have launched 15+ products in 7+ countries
      </div>
    </div>
  );
};

export default Stackingpageshome;
